@tailwind base;
@tailwind components;
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

@tailwind utilities;

@layer utilities {
  .scrolling-banner {
    @apply fixed left-0 w-full h-8 bg-primary overflow-hidden flex items-center z-50;
    top: 0rem; /* Adjust this value to move the banner higher */
  }

  .scrolling-text {
    @apply inline-block whitespace-nowrap;
    animation: scroll-left 20s linear infinite; /* Adjust the duration as needed */
  }

  .scrolling-text p {
    display: inline-block;
    padding-left: 20%; /* Ensure the content starts off-screen */
  }

  @keyframes scroll-left {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

@layer base {
  :root {
    --gradient: #7815e2;

    --background: 286 53.949999999999996% 3.92%;
    --foreground: 286 8.299999999999999% 97.45%;

    --muted: 286 41.5% 14.7%;
    --muted-foreground: 286 8.299999999999999% 54.9%;

    --popover: 286 48.400000000000006% 6.37%;
    --popover-foreground: 286 8.299999999999999% 97.45%;

    --card: 286 48.400000000000006% 6.37%;
    --card-foreground: 286 8.299999999999999% 97.45%;

    --border: 286 41.5% 14.7%;
    --input: 286 41.5% 14.7%;

    --primary: 286 83% 49%;
    --primary-foreground: 286 8.299999999999999% 4.9%;

    --secondary: 286 41.5% 14.7%;
    --secondary-foreground: 286 8.299999999999999% 97.45%;

    --accent: 286 41.5% 14.7%;
    --accent-foreground: 286 8.299999999999999% 97.45%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 286 8.299999999999999% 97.45%;

    --ring: 286 83% 49%;
    --chart-1: 120 70% 60%; /* Pet Hop - Playful Green */
    --chart-2: 200 75% 55%; /* Bubble Invasion - Bubble Blue */
    --chart-3: 35 85% 65%; /* Fruit Slice Frenzy - Fruity Orange */
    --chart-4: 195 65% 55%; /* Bird Bump - Sky Blue */
    --chart-5: 280 45% 45%; /* Mountain Hop - Mountain Purple */
    --chart-6: 200 80% 45%; /* Atlantic Sky Hunter - Deep Ocean Blue */
    --chart-7: 0 0% 20%; /* Leave Me Alone - Dark Gray */
    --chart-8: 15 80% 50%; /* Karate Chop Kick - Martial Arts Red */
    --chart-9: 190 85% 60%; /* Surfing Hero - Ocean Blue */
    --chart-10: 145 50% 50%; /* Wordler - Word Green */
    --chart-11: 265 70% 55%; /* Spore Hunter HD - Alien Purple */
    --chart-12: 0 70% 45%; /* Omg Zombie Run - Blood Red */
    --chart-13: 195 90% 55%; /* Electron Dash - Electric Blue */
    --chart-14: 150 45% 45%; /* Koala Sling - Eucalyptus Green */
    --chart-15: 40 85% 60%; /* Cool Archer HD - Golden Yellow */
    --chart-16: 15 75% 50%; /* Rally Racing - Racing Red */
    --chart-17: 180 70% 55%; /* Jump and Splat - Splash Blue */
    --chart-18: 0 0% 85%; /* Knife Master - Steel Gray */
    --chart-19: 240 70% 60%; /* Starship Defender - Space Blue */
    --chart-20: 45 85% 65%; /* Temple Dash Mania - Temple Gold */
    --chart-21: 300 60% 50%; /* Magnificent Tower - Royal Purple */
    --chart-22: 5 70% 45%; /* Zombies Are Coming - Dark Red */
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 120 70% 60%; /* Pet Hop - Playful Green */
    --chart-2: 200 75% 55%; /* Bubble Invasion - Bubble Blue */
    --chart-3: 35 85% 65%; /* Fruit Slice Frenzy - Fruity Orange */
    --chart-4: 195 65% 55%; /* Bird Bump - Sky Blue */
    --chart-5: 280 45% 45%; /* Mountain Hop - Mountain Purple */
    --chart-6: 200 80% 45%; /* Atlantic Sky Hunter - Deep Ocean Blue */
    --chart-7: 0 0% 20%; /* Leave Me Alone - Dark Gray */
    --chart-8: 15 80% 50%; /* Karate Chop Kick - Martial Arts Red */
    --chart-9: 190 85% 60%; /* Surfing Hero - Ocean Blue */
    --chart-10: 145 50% 50%; /* Wordler - Word Green */
    --chart-11: 265 70% 55%; /* Spore Hunter HD - Alien Purple */
    --chart-12: 0 70% 45%; /* Omg Zombie Run - Blood Red */
    --chart-13: 195 90% 55%; /* Electron Dash - Electric Blue */
    --chart-14: 150 45% 45%; /* Koala Sling - Eucalyptus Green */
    --chart-15: 40 85% 60%; /* Cool Archer HD - Golden Yellow */
    --chart-16: 15 75% 50%; /* Rally Racing - Racing Red */
    --chart-17: 180 70% 55%; /* Jump and Splat - Splash Blue */
    --chart-18: 0 0% 85%; /* Knife Master - Steel Gray */
    --chart-19: 240 70% 60%; /* Starship Defender - Space Blue */
    --chart-20: 45 85% 65%; /* Temple Dash Mania - Temple Gold */
    --chart-21: 300 60% 50%; /* Magnificent Tower - Royal Purple */
    --chart-22: 5 70% 45%; /* Zombies Are Coming - Dark Red */
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    margin: 0;
    padding: 0;

    /* background-image: radial-gradient(#e5bdcd 0.5px, transparent 0.5px);
    background-size: 22px 22px;
    background-color: #190c26; */

    background-image: linear-gradient(#e58eb11b 0.5px, transparent 0.5px),
      linear-gradient(to right, #e58eb11b 0.5px, transparent 0.5px);
    background-size: 22px 22px;
    width: 100%;
    height: 100vh;
  }
}

/* crt texture */
.crt-texture {
  width: 100%;
  background-color: #190c26;
  background-image: radial-gradient(
      circle at center,
      rgb(66 18 157 / 32%) 0%,
      transparent 70%
    ),
    repeating-linear-gradient(
      to bottom,
      rgb(255 255 255 / 2%),
      rgb(62 2 91 / 13%) 1px,
      transparent 1px,
      transparent 2px
    );
  background-size: 100% 4px, 100% 4px;
  /* backdrop-filter: blur(10rem) opacity(0.1); */
  background-blend-mode: screen, normal;
}

.active-card {
  position: relative;
  padding: 16px;
  background-color: #1f1f1f;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid transparent;
  z-index: 0;
}

.active-card.selected {
  border-image: linear-gradient(
      90deg,
      rgba(130, 109, 255, 1),
      rgba(177, 95, 240, 0.8)
    )
    1;
  border-image-slice: 1;
  animation: rotate-border 2s linear infinite,
    pulsate-border 2s ease-in-out infinite alternate;
}

@keyframes rotate-border {
  0% {
    border-image-source: linear-gradient(
      90deg,
      rgba(130, 109, 255, 1),
      rgba(177, 95, 240, 0.8)
    );
  }
  50% {
    border-image-source: linear-gradient(
      180deg,
      rgba(95, 240, 177, 1),
      rgba(240, 95, 177, 0.8)
    );
  }
  100% {
    border-image-source: linear-gradient(
      450deg,
      rgba(130, 109, 255, 1),
      rgba(177, 95, 240, 0.8)
    );
  }
}

@keyframes pulsate-border {
  0% {
    border-width: 2px;
  }
  50% {
    border-width: 2px;
  }
  100% {
    border-width: 2px;
  }
}

.embla {
  --slide-spacing: 1rem;
  --slide-size: 60%;
  --slide-height: 19rem;
  padding: 1.6rem;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
  position: relative;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.embla__class-names {
  transition: opacity 0.2s ease-in-out;
}
.embla__class-names:not(.is-snapped) {
  opacity: 0.16;
}

.marquee {
  overflow: hidden;
  position: absolute;
  top: 48%;
  left: 0;
  padding: 0.15em 0;
  width: 100%;
}
.marquee-content {
  display: flex;
  width: calc(
    500% - 100%
  ); /* 200% is your content width, 100% is the viewport width */

  animation: marquee 15s linear infinite; /* Reduced from 30s to 20s for faster scrolling */
}
.marquee-content > div {
  flex-shrink: 0;
  width: 33.3333%; /* Since the content is duplicated, each needs to be half of the content width */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2vw; /* Adjust the padding to manage spacing */
}

.marquee-content::before,
.marquee-content::after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 1;
  width: 1.5em; /* This creates a fade effect with a fixed width */
  height: 100%;
  pointer-events: none;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250% + 100vw));
  }
}
div[data-rk] {
  display: contents;
}

@keyframes particleAnimation {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(var(--end-x), var(--end-y));
    opacity: 0;
  }
}

@layer utilities {
  .particle-container {
    position: relative;
    overflow: hidden;
  }

  .particle {
    position: absolute;
    width: 5px;
    height: 5px;
    background: radial-gradient(
      circle,
      var(--secondary-color, rgba(255, 255, 255, 0.8)) 80%,
      var(--primary-color, rgba(255, 255, 255, 0)) 70%
    );
    border-radius: 50%;
    pointer-events: none;
    opacity: 0;
    animation: particleAnimation 3s ease-out infinite;
  }

  .particle:nth-child(even) {
    width: 7px;
    height: 7px;
    animation-duration: 4s;
  }

  .particle:nth-child(3n) {
    width: 3px;
    height: 3px;
    animation-duration: 2.5s;
  }

  .particle-container:hover .particle {
    animation-duration: 2s;
  }
}

.create-particles {
  animation: none;
  transition: all 0.3s ease;
}

.create-particles:hover {
  filter: brightness(1.1);
}

.create-particles:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  pointer-events: none;
}

.leaderboard {
  grid-area: leaderboard;
}
.game-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* This applies up to 1280px */

  gap: 1.25rem; /* 20px equivalent */
  width: 100%;
  min-height: 430px;
  /* max-height: max-content;  */
  grid-template-areas:
    "leaderboard leaderboard leaderboard leaderboard leaderboard"
    "leaderboard leaderboard leaderboard leaderboard leaderboard";
}
/* grid-template-areas: 
    "child1 child1 child1 child1 child1"; */
/* Responsive Grid Columns for screens wider than 1280px */
@media (min-width: 80rem) {
  .game-grid {
    grid-template-columns: repeat(
      8,
      1fr
    ); /* Adjusted column count for wider screens */
    grid-template-areas:
      "leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard"
      "leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard"
      "leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard"
      "leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard leaderboard";
  }
}
.subtle-glow {
  box-shadow: rgb(100 0 176 / 28%) 0px 0px 50px;
}
.game-card {
  position: relative;
  box-shadow: rgba(177, 95, 240, 0.15) 0px 0px 50px;
}
.card-border {
  border-color: #826dff; /* your desired border color */
  border-style: solid;
  border-width: 1px;
}
.game-card::before,
.game-card::after {
  content: "";
  position: absolute;
  border-color: #826dff; /* your desired border color */
  border-style: solid;
  border-width: 1px; /* your desired border thickness */
  width: 20px; /* Start with a width of 0 */
  height: 20px; /* your desired corner height */
  transition: width 0.3s ease; /* Animate width changes over 0.3 seconds */
}

.game-card::before {
  top: 0;
  left: 0;
  border-right: none;
  border-bottom: none;
}

.game-card::after {
  bottom: 0;
  right: 0;
  border-top: none;
  border-left: none;
}

.game-card:hover::before,
.game-card:hover::after {
  width: 70%; /* Animate to your desired corner width on hover */
}
.futuristic-text {
  color: #cab9e0; /* Your chosen color */
  text-shadow: rgba(202, 185, 224, 0.5) 0px 4px 4px,
    rgba(202, 185, 224, 0.5) 0px 0px 2px, rgb(114 5 255) 0px 0px 4px,
    rgb(111 0 255 / 90%) 0px 0px 10px, rgb(193 145 255 / 60%) -1px 8px 13px;
}

/* hud btn  */

/* ::before pseudo-element for decoration */
.hud-btn::before {
  content: "";
  position: absolute;
  /* Position the element at the start of the button, for example */
  top: 50%;
  left: -1rem;
  width: 10px;
  height: 10px;
  /* Your styles for the decoration */
  background: rgb(255, 0, 136); /* Light pink background */
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.64, -0.01, 0.11, 0.95);
}

/* ::after pseudo-element for decoration */
.hud-btn::after {
  content: "";
  position: absolute;
  /* Position the element at the end of the button, for example */
  top: 50%;
  right: -1rem;
  width: 10px;
  height: 10px;
  /* Your styles for the decoration */
  background: rgb(255, 0, 136); /* Slightly darker pink background */
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.64, -0.01, 0.11, 0.95);
}
.hud-btn:hover::before,
.hud-btn:hover::after {
  opacity: 1; /* Make them visible on hover */
}

/* button {
  background-color: none;
  text-decoration: none;
  background-color: #212121;
  border: none;
} */
.slider-gradient {
  background: linear-gradient(
    180deg,
    rgb(18 13 45 / 40%) 0%,
    rgb(6 2 28 / 40%) 0%,
    rgb(21 20 28 / 5%) 100%
  );
}
/* hero txt */
/* Base styling for the container and text */
.hero {
  filter: drop-shadow(0 1px 3px);
}
.layers {
  position: relative;
}

.layers::before,
.layers::after {
  content: attr(data-text);
  position: absolute;
  width: 110%;
  z-index: -1;
}

.layers::before {
  top: 10px;
  left: 15px;
  color: #e0287d;
}

.layers::after {
  top: 5px;
  left: -10px;
  color: #1bc7fb;
}

.single-path {
  clip-path: polygon(
    0% 12%,
    53% 12%,
    53% 26%,
    25% 26%,
    25% 86%,
    31% 86%,
    31% 0%,
    53% 0%,
    53% 84%,
    92% 84%,
    92% 82%,
    70% 82%,
    70% 29%,
    78% 29%,
    78% 65%,
    69% 65%,
    69% 66%,
    77% 66%,
    77% 45%,
    85% 45%,
    85% 26%,
    97% 26%,
    97% 28%,
    84% 28%,
    84% 34%,
    54% 34%,
    54% 89%,
    30% 89%,
    30% 58%,
    83% 58%,
    83% 5%,
    68% 5%,
    68% 36%,
    62% 36%,
    62% 1%,
    12% 1%,
    12% 34%,
    60% 34%,
    60% 57%,
    98% 57%,
    98% 83%,
    1% 83%,
    1% 53%,
    91% 53%,
    91% 84%,
    8% 84%,
    8% 83%,
    4% 83%
  );
}

.paths {
  animation: paths 5s step-end infinite;
}

@keyframes paths {
  0% {
    clip-path: polygon(
      0% 43%,
      83% 43%,
      83% 22%,
      23% 22%,
      23% 24%,
      91% 24%,
      91% 26%,
      18% 26%,
      18% 83%,
      29% 83%,
      29% 17%,
      41% 17%,
      41% 39%,
      18% 39%,
      18% 82%,
      54% 82%,
      54% 88%,
      19% 88%,
      19% 4%,
      39% 4%,
      39% 14%,
      76% 14%,
      76% 52%,
      23% 52%,
      23% 35%,
      19% 35%,
      19% 8%,
      36% 8%,
      36% 31%,
      73% 31%,
      73% 16%,
      1% 16%,
      1% 56%,
      50% 56%,
      50% 8%
    );
  }

  5% {
    clip-path: polygon(
      0% 29%,
      44% 29%,
      44% 83%,
      94% 83%,
      94% 56%,
      11% 56%,
      11% 64%,
      94% 64%,
      94% 70%,
      88% 70%,
      88% 32%,
      18% 32%,
      18% 96%,
      10% 96%,
      10% 62%,
      9% 62%,
      9% 84%,
      68% 84%,
      68% 50%,
      52% 50%,
      52% 55%,
      35% 55%,
      35% 87%,
      25% 87%,
      25% 39%,
      15% 39%,
      15% 88%,
      52% 88%
    );
  }

  30% {
    clip-path: polygon(
      0% 53%,
      93% 53%,
      93% 62%,
      68% 62%,
      68% 37%,
      97% 37%,
      97% 89%,
      13% 89%,
      13% 45%,
      51% 45%,
      51% 88%,
      17% 88%,
      17% 54%,
      81% 54%,
      81% 75%,
      79% 75%,
      79% 76%,
      38% 76%,
      38% 28%,
      61% 28%,
      61% 12%,
      55% 12%,
      55% 62%,
      68% 62%,
      68% 51%,
      0% 51%,
      0% 92%,
      63% 92%,
      63% 4%,
      65% 4%
    );
  }

  45% {
    clip-path: polygon(
      0% 33%,
      2% 33%,
      2% 69%,
      58% 69%,
      58% 94%,
      55% 94%,
      55% 25%,
      33% 25%,
      33% 85%,
      16% 85%,
      16% 19%,
      5% 19%,
      5% 20%,
      79% 20%,
      79% 96%,
      93% 96%,
      93% 50%,
      5% 50%,
      5% 74%,
      55% 74%,
      55% 57%,
      96% 57%,
      96% 59%,
      87% 59%,
      87% 65%,
      82% 65%,
      82% 39%,
      63% 39%,
      63% 92%,
      4% 92%,
      4% 36%,
      24% 36%,
      24% 70%,
      1% 70%,
      1% 43%,
      15% 43%,
      15% 28%,
      23% 28%,
      23% 71%,
      90% 71%,
      90% 86%,
      97% 86%,
      97% 1%,
      60% 1%,
      60% 67%,
      71% 67%,
      71% 91%,
      17% 91%,
      17% 14%,
      39% 14%,
      39% 30%,
      58% 30%,
      58% 11%,
      52% 11%,
      52% 83%,
      68% 83%
    );
  }

  76% {
    clip-path: polygon(
      0% 26%,
      15% 26%,
      15% 73%,
      72% 73%,
      72% 70%,
      77% 70%,
      77% 75%,
      8% 75%,
      8% 42%,
      4% 42%,
      4% 61%,
      17% 61%,
      17% 12%,
      26% 12%,
      26% 63%,
      73% 63%,
      73% 43%,
      90% 43%,
      90% 67%,
      50% 67%,
      50% 41%,
      42% 41%,
      42% 46%,
      50% 46%,
      50% 84%,
      96% 84%,
      96% 78%,
      49% 78%,
      49% 25%,
      63% 25%,
      63% 14%
    );
  }

  90% {
    clip-path: polygon(
      0% 41%,
      13% 41%,
      13% 6%,
      87% 6%,
      87% 93%,
      10% 93%,
      10% 13%,
      89% 13%,
      89% 6%,
      3% 6%,
      3% 8%,
      16% 8%,
      16% 79%,
      0% 79%,
      0% 99%,
      92% 99%,
      92% 90%,
      5% 90%,
      5% 60%,
      0% 60%,
      0% 48%,
      89% 48%,
      89% 13%,
      80% 13%,
      80% 43%,
      95% 43%,
      95% 19%,
      80% 19%,
      80% 85%,
      38% 85%,
      38% 62%
    );
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    clip-path: none;
  }
}

.movement {
  /* Normally this position would be absolute & on the layers, set to relative here so we can see it on the div */
  position: relative;
  animation: movement 8s step-end infinite;
}

@keyframes movement {
  0% {
    top: 0px;
    left: -20px;
  }

  15% {
    top: 10px;
    left: 10px;
  }

  60% {
    top: 5px;
    left: -10px;
  }

  75% {
    top: -5px;
    left: 20px;
  }

  100% {
    top: 10px;
    left: 5px;
  }
}

.opacity {
  animation: opacity 5s step-end infinite;
}

@keyframes opacity {
  0% {
    opacity: 0.1;
  }

  5% {
    opacity: 0.7;
  }

  30% {
    opacity: 0.4;
  }

  45% {
    opacity: 0.6;
  }

  76% {
    opacity: 0.4;
  }

  90% {
    opacity: 0.8;
  }

  1%,
  7%,
  33%,
  47%,
  78%,
  93% {
    opacity: 0;
  }
}

.font {
  animation: font 7s step-end infinite;
}

@keyframes font {
  0% {
    font-weight: 100;
    color: #e0287d;
    filter: blur(3px);
  }

  20% {
    font-weight: 500;
    color: #fff;
    filter: blur(0);
  }

  50% {
    font-weight: 300;
    color: #1bc7fb;
    filter: blur(2px);
  }

  60% {
    font-weight: 700;
    color: #fff;
    filter: blur(0);
  }

  90% {
    font-weight: 500;
    color: #e0287d;
    filter: blur(6px);
  }
}

.glitch span {
  animation: paths 5s step-end infinite;
}

.glitch::before {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 8s step-end infinite, movement 10s step-end infinite;
}

.glitch::after {
  animation: paths 5s step-end infinite, opacity 5s step-end infinite,
    font 7s step-end infinite, movement 8s step-end infinite;
}

/* css rect */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #1a1a1a;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  width: 33.33%;
  height: 150px; /* Adjust the height as needed */
  position: relative;
  overflow: hidden;
  border: 1px solid #7815e2;
}

.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  pointer-events: none; /* Ensures that the overlay does not interfere with mouse events */
  background: linear-gradient(to bottom, #000, transparent);
  background-blend-mode: overlay;
}

.animated-div {
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 32px);
  height: 60px; /* Adjust the height as needed */
  background-color: #7815e2;
  position: absolute;
  bottom: -60px; /* Start below the container, same as the height of the div */
  animation: slideUp 5s linear infinite;
}

@keyframes slideUp {
  0% {
    bottom: -60px;
  }
  100% {
    bottom: 300px; /* Same as the container's height */
  }
}
.corner {
  position: relative;
}

/* Top-left corner */
.corner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-top: 1px solid #826dff; /* your desired border color and thickness */
  border-left: 1px solid #826dff;
  width: 20px; /* your desired corner width */
  height: 20px; /* your desired corner height */
}

/* Top-right corner */
.corner::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-top: 1px solid #826dff;
  border-right: 1px solid #826dff;
  width: 20px; /* your desired corner width */
  height: 20px; /* your desired corner height */
}

/* Inner div for bottom corners */
.corner > .inner {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

/* Bottom-left corner */
.corner > .inner::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #826dff;
  border-left: 1px solid #826dff;
  width: 20px; /* your desired corner width */
  height: 20px; /* your desired corner height */
}

/* Bottom-right corner */
.corner > .inner::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 1px solid #826dff;
  border-right: 1px solid #826dff;
  width: 20px; /* your desired corner width */
  height: 20px; /* your desired corner height */
}

[class^="TokenOptions__OnHover"] {
  display: none !important;
}

[type="text"]:focus {
  box-shadow: none;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

/* Add this with your other animations */
.halloween-text {
  text-shadow: 0 4px 4px rgba(255, 140, 0, 0.5), 0 0 2px rgba(255, 140, 0, 0.5),
    0 0 4px rgb(255, 69, 0), 0 0 10px rgba(255, 69, 0, 0.9),
    -1px 8px 13px rgba(255, 140, 0, 0.6);
}

/* Add this with your other keyframes */
@keyframes flicker {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
}

@layer base {
  .dark,
  .light,
  .default-dark {
    --ock-font-family: var(--font-bai);
    --ock-border-radius: var(--radius);
    --ock-border-radius-inner: calc(var(--radius) - 2px);
    --ock-text-inverse: hsl(286 53.949999999999996% 3.92%);
    --ock-text-foreground: hsl(286 8.299999999999999% 97.45%);
    --ock-text-foreground-muted: hsl(286 8.299999999999999% 54.9%);
    --ock-text-error: hsl(0 62.8% 30.6%);
    --ock-text-primary: hsl(286 83% 49%);
    --ock-text-success: hsl(120 70% 60%);
    --ock-text-warning: hsl(35 85% 65%);
    --ock-text-disabled: hsl(286 41.5% 14.7%);

    --ock-bg-default: hsl(286 53.949999999999996% 3.92%);
    --ock-bg-default-hover: hsl(286 41.5% 14.7%);
    --ock-bg-default-active: hsl(286 41.5% 14.7%);
    --ock-bg-alternate: hsl(286 41.5% 14.7%);
    --ock-bg-alternate-hover: hsl(286 41.5% 14.7%);
    --ock-bg-alternate-active: hsl(286 8.299999999999999% 97.45%);
    --ock-bg-inverse: hsl(286 8.299999999999999% 97.45%);
    --ock-bg-inverse-hover: hsl(286 8.299999999999999% 54.9%);
    --ock-bg-inverse-active: hsl(286 8.299999999999999% 97.45%);
    --ock-bg-primary: hsl(286, 83%, 49%) !important;
    --ock-bg-primary-hover: hsl(286 83% 49%);
    --ock-bg-primary-active: hsl(286 8.299999999999999% 4.9%);
    --ock-bg-primary-washed: hsl(286 83% 49% / 0.2);
    --ock-bg-primary-disabled: hsl(286 41.5% 14.7%);
    --ock-bg-secondary: hsl(286 41.5% 14.7%);
    --ock-bg-secondary-hover: hsl(286 41.5% 14.7%);
    --ock-bg-secondary-active: hsl(286 8.299999999999999% 97.45%);
    --ock-bg-error: hsl(0 62.8% 30.6%);
    --ock-bg-warning: hsl(35 85% 65%);
    --ock-bg-success: hsl(120 70% 60%);
    --ock-bg-default-reverse: hsl(286 8.299999999999999% 97.45%);

    --ock-icon-color-primary: hsl(286 83% 49%);
    --ock-icon-color-foreground: hsl(286 8.299999999999999% 97.45%);
    --ock-icon-color-foreground-muted: hsl(286 8.299999999999999% 54.9%);
    --ock-icon-color-inverse: hsl(286 53.949999999999996% 3.92%);
    --ock-icon-color-error: hsl(0 62.8% 30.6%);
    --ock-icon-color-success: hsl(120 70% 60%);
    --ock-icon-color-warning: hsl(35 85% 65%);

    --ock-line-primary: hsl(286 83% 49%);
    --ock-line-default: hsl(286 41.5% 14.7%);
    --ock-line-heavy: hsl(286 83% 49%);
    --ock-line-inverse: hsl(286 53.949999999999996% 3.92%);
  }
}
